import moment from "moment";

function updateDietPlanTimes(data) {
    data.weeks.forEach(week => {
        week.days.forEach(day => {
            day.dietPlans.forEach(plan => {
                const momentTime = moment(plan.time);
                plan.time = momentTime;
            });
        });
    });

    return data;
}

function setDietPlan(data) {
	return {
		type: 'SET_DIET_PLAN',
		payload: data,
	};
}

function setDietPlanForEdit(data) {
	const updatedData = updateDietPlanTimes(data);
	return {
		type: 'SET_DIET_PLAN_BY_ID',
		payload: updatedData,
	};
}

export { setDietPlan, setDietPlanForEdit };
