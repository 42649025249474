import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS, DIET_PLAN_CATEGORY_LIST } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import DietPlanTemplateListPresentational from './diet-plan-template-list-presentational';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const DietPlanTemplateListFunctional = React.memo(() => {
	const dietPlanTemplateRedux = useSelector((state) => state.dietPlanTemplateRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [dietPlanTemplateAddModal, setDietPlanTemplateAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(dietPlanTemplateRedux.dietPlanTemplate);
	const [editDietPlanTemplate, setEditDietPlanTemplate] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getPeriods = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/period?${string}`;
		dispatch(getApi('GET_PERIODS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getPeriods();
	}, [getPeriods]);

	const getDietPlanTemplate = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/dietPlanTemplate?${string}`;
		dispatch(getApi('GET_DIET_PLAN_TEMPLATE', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getDietPlanTemplate();
	}, [getDietPlanTemplate]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_PT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_DIET_PLAN_TEMPLATE'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getDietPlanTemplate();
		}
	}, [globalRedux.apiStatus.DELETE_PT, dispatch, getDietPlanTemplate]);

	useEffect(() => {
		setTableData(dietPlanTemplateRedux.dietPlanTemplate);
	}, [dietPlanTemplateRedux.dietPlanTemplate]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((dietPlanTemplate) => {
			return (dietPlanTemplate?.dietPlanTemplateName || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditDietPlanTemplate(rowData);
		setDietPlanTemplateAddModal(true);
	};

	const handleAddDietPlanTemplate = () => {
		setEditDietPlanTemplate(null);
		setDietPlanTemplateAddModal(true);
	};


	const column = [
		{
			title: '#',
			dataIndex: 'dietPlanTemplateNumber',
			key: 'dietPlanTemplateNumber',
			// fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Template Name',
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => a?.localeCompare(b),
			render: (value, record) => (
				<Row align={'middle'}>
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value || '-'}
						/>
					</div>
				</Row>
			),
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category',
			sorter: (a, b) => a?.localeCompare(b),
			render: (value, record) => {
				const category = DIET_PLAN_CATEGORY_LIST.filter((item) => item.id === value)[0].categoryName;
				return <Row align={'middle'}>
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={category || '-'}
						/>
					</div>
				</Row>
			},
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			// width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'dietPlanTemplateName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_DIET_PLAN === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setDietPlanTemplateAddModal(false);
		setEditDietPlanTemplate(null);
	}, [setDietPlanTemplateAddModal, setEditDietPlanTemplate]);

	return (
		<DietPlanTemplateListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddDietPlanTemplate,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				dietPlanTemplateAddModal,
				setDietPlanTemplateAddModal,
				refreshList: getDietPlanTemplate,
				editDietPlanTemplate,
				handleClose,
			}}
		/>
	);
});

export default DietPlanTemplateListFunctional;
