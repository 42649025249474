import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Form, Drawer, DatePicker, Radio, InputNumber, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, DATE_FORMAT, GYM_PACKAGES, PAYMENT_METHOD } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import dayjs from 'dayjs';

const AddGym = ({ gymAddModal, width = '40%', editGym, setGymAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const packageValue = Form.useWatch('package', form);
	const amountValue = Form.useWatch('amount', form);
	const paidOnValue = Form.useWatch('paidOn', form);

	useEffect(() => {
		if (editGym) {
			form.setFieldsValue({
				firstName: editGym?.firstName || '',
				lastName: editGym?.lastName || '',
				gymDescription: editGym?.gymDescription || '',
				gymName: editGym?.gymName || '',
				gstin: editGym?.gstin || '',
				email: editGym?.email || '',
				mobile: editGym?.mobile || '',
				aadhaarNo: editGym?.aadhaarNo || '',
				facebook: editGym?.socialLinks?.facebook,
				instagram: editGym?.socialLinks?.instagram,
				youtube: editGym?.socialLinks?.youtube,
			});
		} else {
			form?.resetFields();
		}
	}, [editGym, form]);

	useEffect(() => {
		if (!!packageValue) {
			const packageInd = GYM_PACKAGES.findIndex((item) => item.value === packageValue);
			const gymPackage = GYM_PACKAGES[packageInd];
			const paidOn = dayjs();
			const nextBillDate = dayjs(paidOn).add(gymPackage?.days, 'days');
			form.setFieldsValue({
				amount: GYM_PACKAGES[packageInd]?.price,
				total: GYM_PACKAGES[packageInd]?.price,
				balance: 0,
				package: GYM_PACKAGES[packageInd]?.value,
				paidOn,
				nextBillDate: nextBillDate,
			});
		}
	}, [packageValue, form]);

	useEffect(() => {
		if (!!amountValue) {
			const packageInd = GYM_PACKAGES.findIndex((item) => item.value === packageValue);
			const gymPackage = GYM_PACKAGES[packageInd];
			const balance = gymPackage?.price - amountValue;
			form.setFieldsValue({
				balance,
			});
		}
	}, [amountValue, form, packageValue]);

	const handleSubmit = (values) => {
		let data = {
			firstName: values?.firstName || '',
			lastName: values?.lastName || '',
			gymDescription: values?.gymDescription || '',
			gymName: values?.gymName || '',
			gstin: values?.gstin || '',
			email: values?.email || '',
			...(!!!editGym && { password: values?.password || '', role: 'admin' }),
			mobile: values?.mobile || '',
			aadhaarNo: values?.aadhaarNo || '',
			package: values?.package,
			nextBillDate: values?.nextBillDate,
			socialLinks: {
				facebook: values?.facebook,
				instagram: values?.instagram,
				youtube: values?.youtube,
			},
			paymentDetails: {
				package: values?.package,
				amount: values?.amount,
				balance: values?.balance,
				paidOn: values?.paidOn,
				nextBillDate: values?.nextBillDate,
				paymentMethod: values?.paymentMethod,
				...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			},
		};
		if (!editGym) {
			console.log('POST API', { data });
			dispatch(postApi(data, 'ADD_USER'));
		} else {
			let url = `${SERVER_IP}api/users/${editGym.id}`;
			dispatch(putApi(data, 'EDIT_GYM', url));
		}
	};

	useEffect(() => {
		if (!!paidOnValue) {
			const packageInd = GYM_PACKAGES.findIndex((item) => item.value === packageValue);
			const gymPackage = GYM_PACKAGES[packageInd];
			const nextBillDate = dayjs(paidOnValue).add(gymPackage?.days, 'days');
			form.setFieldsValue({
				nextBillDate: nextBillDate,
			});
		}
	}, [paidOnValue, form, packageValue]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_USER === 'SUCCESS' || globalRedux.apiStatus.EDIT_GYM === 'SUCCESS') {
			dispatch(resetApiStatus(editGym ? 'EDIT_GYM' : 'ADD_USER'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editGym, setGymAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_USER === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_GYM === API_STATUS.PENDING;

	const onCancel = () => {
		setGymAddModal(false);
		form?.resetFields();
	};

	return (
		<Drawer
			placement="right"
			title={`${editGym ? 'Edit' : 'New'} Gym`}
			width={width || '40%'}
			open={gymAddModal}
			closable
			onClose={onCancel}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
						<Col>
							<h6 style={{ marginBottom: '0px' }}>{editGym ? 'Edit' : 'New'} Gym</h6>
						</Col>
					</Row>
					<Divider /> */}
					<Row style={{ marginTop: 0 }}>
						<Form
							name="add-gym"
							className="required_in_right"
							style={{ width: '100%' }}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item label="Gym Display Name" name="gymName" rules={[{ required: true, message: 'This Field is required!' }]}>
								<Input placeholder="Enter the gym's display name" />
							</Form.Item>
							<Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'This Field is required!' }]}>
								<Input placeholder="Enter the owner's first name" />
							</Form.Item>
							<Form.Item label="Last Name" name="lastName">
								<Input placeholder="Enter the owner's last name" />
							</Form.Item>
							<Form.Item label="Description" name="gymDescription">
								<Input.TextArea placeholder="Provide a brief description of the gym" />
							</Form.Item>
							<Form.Item label="GSTIN" name="gstin" rules={[{ required: true, message: 'This Field is required!' }]}>
								<Input placeholder="Enter the GSTIN" />
							</Form.Item>
							<Form.Item label="Mobile" name="mobile" rules={[{ required: true, message: 'This Field is required!' }]}>
								<Input placeholder="Enter the mobile number" maxLength={10} />
							</Form.Item>
							<Form.Item label="Email" name="email" rules={[{ required: true, message: 'This Field is required!' }]}>
								<Input placeholder="Enter the email address" />
							</Form.Item>
							{!!!editGym && (
								<Form.Item label="Password" name="password" rules={[{ required: true, message: 'This Field is required!' }]}>
									<Input.Password placeholder="Enter the password" />
								</Form.Item>
							)}
							<Form.Item label="Aadhaar" name="aadhaarNo" rules={[{ required: true, message: 'This Field is required!' }]}>
								<Input placeholder="Enter the Aadhaar number" />
							</Form.Item>
							{!!!editGym && (
								<Row>
									<Col span={24} style={{ fontWeight: 'bold' }}>
										Package Details
									</Col>
									<Col span={24}>
										<Form.Item label="Plan" name="package" rules={[{ required: true, message: 'This Field is required!' }]}>
											<Select placeholder="Select a package">
												{GYM_PACKAGES?.map((pckg, index) => (
													<Select.Option key={index} value={pckg.value}>
														{pckg?.name}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										{!!packageValue && (
											<>
												<Form.Item label="Total Amount" name="total">
													<InputNumber disabled placeholder="Total amount" style={{ width: '100%' }} />
												</Form.Item>
												<Form.Item label="Paid Amount" name="amount">
													<InputNumber placeholder="Enter the amount paid" style={{ width: '100%' }} />
												</Form.Item>
												<Form.Item label="Balance" name="balance">
													<InputNumber disabled placeholder="Balance amount" style={{ width: '100%' }} />
												</Form.Item>
												<Form.Item
													label="Paid on"
													name="paidOn"
													rules={[{ required: true, message: 'This Field is required!' }]}>
													<DatePicker
														style={{ width: '100%' }}
														format={DATE_FORMAT.DD_MM_YYYY}
														placeholder="Select the payment date"
													/>
												</Form.Item>
												<Form.Item
													label="Next Bill Date"
													name="nextBillDate"
													rules={[{ required: true, message: 'This Field is required!' }]}>
													<DatePicker
														style={{ width: '100%' }}
														format={DATE_FORMAT.DD_MM_YYYY}
														placeholder="Select the next billing date"
													/>
												</Form.Item>
												<Form.Item
													label="Payment Method"
													name="paymentMethod"
													rules={[{ required: true, message: 'This Field is required!' }]}>
													<Radio.Group
														style={{ width: '100%' }}
														options={PAYMENT_METHOD}
														optionType="button"
														buttonStyle="solid"
														placeholder="Choose payment method"
													/>
												</Form.Item>
												{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
													<Form.Item
														label="UPI TransactionId"
														name="upiTransactionId"
														rules={[{ required: true, message: 'This Field is required!' }]}>
														<InputNumber placeholder="Enter the UPI Transaction ID" style={{ width: '100%' }} />
													</Form.Item>
												)}
											</>
										)}
									</Col>
								</Row>
							)}
							<Row>
								<Col span={24} style={{ fontWeight: 'bold' }}>
									Social Links
								</Col>
								<Col span={24}>
									<Form.Item label="Facebook" name="facebook">
										<Input placeholder="Enter Facebook link" />
									</Form.Item>
									<Form.Item label="Instagram" name="instagram">
										<Input placeholder="Enter Instagram link" />
									</Form.Item>
									<Form.Item label="Youtube" name="youtube">
										<Input placeholder="Enter YouTube link" />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={onCancel}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editGym ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddGym;
