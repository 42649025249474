import React, { useEffect, useState } from 'react';
import { Col, Progress, Row } from 'antd';

const PageLoader = () => {
	const [percent, setPercent] = useState(0);

	useEffect(() => {
		const intervalTime = 15; // Update every 15 milliseconds
		const incrementValue = 100 / (800 / intervalTime); // Increment by 1 every 15ms

		const interval = setInterval(() => {
			setPercent((prevPercent) => {
				if (prevPercent >= 100) {
					clearInterval(interval);
					return 100;
				}
				return prevPercent + incrementValue;
			});
		}, intervalTime);

		return () => clearInterval(interval);
	}, []);

	return (
		<Row className="loader" align="middle">
			<Col span={24}>
				<div className="loader_container">
					<img src={require('../assets/images/logo.png')} style={{ width: 80, height: 80, marginBottom: 40 }} alt="logo" />
					<Progress percent={percent} type="line" status="active" />
					<span className="shimmer-effect">FITTE.in</span>
				</div>
			</Col>
		</Row>
	);
};

export default PageLoader;
