let initialState = {
	dietPlan: [],
	editDietPlan: {}
};

const dietPlanReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_DIET_PLAN':
			return {
				...state,
				dietPlan: action.payload,
			};
		case 'SET_DIET_PLAN_BY_ID':
			return {
				...state,
				editDietPlan: action.payload,
			};
		case 'RESET_DIET_PLAN_BY_ID':
			return {
				...state,
				editDietPlan: {},
			};
		default:
			return state;
	}
};

export { dietPlanReducer };
