import { toast } from 'react-toastify';
import { DATE_FORMAT, NOTIFICATION_STATUS_TYPES } from 'constants/app-constants';
import { ToastifyNotification } from 'components/toast-component';
import moment from 'moment';
import { SERVER_IP } from 'assets/Config';

export const getFirstLetterFromWords = (string = '') => string?.match(/\b\w/g)?.join('');

export const getTheRoundOffValue = (value = 0) => {
	let final = 0;
	const roundValue = Math.round(value);
	final = roundValue - value;
	return parseFloat(final).toFixed(2);
};

export const removeEmptyFields = (obj = {}) => {
	const filteredValue = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ''));
	return {
		filteredValue,
		totalLength: Object.keys(obj).length,
		filteredLength: Object.keys(filteredValue).length,
	};
};
export const getLengthOfObj = (obj = {}) => Object.keys(obj).length;

export const generatePagination = (tableData = []) => {
	if (tableData?.length > 0) {
		const tableBtns = document.getElementsByClassName('ant-pagination-item-link');
		const nextBtn = document.getElementsByClassName('ant-pagination-item-link')[tableBtns.length - 1];
		nextBtn.innerHTML = 'Next >';
		nextBtn.style.paddingLeft = '8px';
		nextBtn.style.paddingRight = '8px';
		const prevBtn = document.getElementsByClassName('ant-pagination-item-link')[0];
		prevBtn.innerHTML = '< Previous';
		prevBtn.style.paddingLeft = '8px';
		prevBtn.style.paddingRight = '8px';
	}
};

export const generatePaginationForServerSide = (tableData = {}) => {
	if (tableData?.length > 0) {
		const tableBtns = document.getElementsByClassName('ant-pagination-item-link');
		const nextBtn = document.getElementsByClassName('ant-pagination-item-link')[tableBtns.length - 1];
		nextBtn.innerHTML = 'Next >';
		nextBtn.style.paddingLeft = '8px';
		nextBtn.style.paddingRight = '8px';
		const prevBtn = document.getElementsByClassName('ant-pagination-item-link')[0];
		prevBtn.innerHTML = '< Previous';
		prevBtn.style.paddingLeft = '8px';
		prevBtn.style.paddingRight = '8px';
	}
};

export const formQueryStringFromObject = (data) =>
	Object.keys(data)
		.filter((key) => data[key] !== '' && data[key] !== undefined && data[key] !== null)
		.map((key) => key + '=' + data[key])
		.join('&');

export function camelize(str) {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
}

export const generateGreetings = () => {
	var currentHour = moment().format('HH');
	if (currentHour >= 3 && currentHour < 12) {
		return 'Good Morning';
	} else if (currentHour >= 12 && currentHour < 15) {
		return 'Good Afternoon';
	} else if (currentHour >= 15 && currentHour < 20) {
		return 'Good Evening';
	} else if (currentHour >= 20 && currentHour < 3) {
		return 'Good Night';
	} else {
		return 'Hello';
	}
};

export const objToQs = (params) =>
	Object.keys(params)
		.map((key) => params[key] && key + '=' + params[key])
		.filter((params) => params)
		.join('&');

export const showToast = (title = '', body = '', type = NOTIFICATION_STATUS_TYPES.SUCCESS) => {
	return toast(<ToastifyNotification type={type} title={title} body={body} />);
};

export const convertToIndianRupees = (number, showRupee = true) => {
	// Check if the input is a number or can be parsed to a number
	if (isNaN(number) && typeof number !== 'number') {
		return 'Invalid input';
	}

	// Convert the number to a string with two decimal places
	number = parseFloat(number).toFixed(2);

	// Split the number into integer and decimal parts
	var parts = number.toString().split('.');
	var rupees = parts[0];
	var paise = parts[1] || '00'; // If no decimal part, default to '00'

	// Add commas for thousands, lakhs, crores, etc.
	var formattedRupees = rupees.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');

	// Create the Indian Rupees format string
	var result = (showRupee ? '₹' : '') + formattedRupees + '.' + paise;
	// var result = '₹' + formattedRupees + '.' + paise;

	// console.log('🚀 ~ convertToIndianRupees ~ result:', result);
	return result;
};

export const getCurrentMonth = () => {
	return moment().format(DATE_FORMAT.MM_YYYY);
};

export const getWhatsAppUrl = (phoneNumber, message) => {
	// Remove non-digit characters
	const formattedPhoneNumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';

	// Check if the phone number is valid
	if (!/^(\d{10,15})$/.test(formattedPhoneNumber)) {
		console.warn(
			'Invalid phone number format. Ensure the number is in international format without spaces or special characters.',
			formattedPhoneNumber
		);
	}

	return `whatsapp://send?phone=+91${formattedPhoneNumber}&text=${encodeURI(message)}`;
};

export const downloadAsFileFromResponseData = async (response, fileName) => {
	try {
		// Create a new Blob object from the response data
		const blob = new Blob([response.data], { type: 'application/pdf' });
		
		// Create a temporary URL for the blob
		const url = window.URL.createObjectURL(blob);
	
		// Create a temporary link element to trigger the download
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
	
		// Append the link to the body and trigger the download
		document.body.appendChild(link);
		link.click();
	
		// Clean up by revoking the blob URL and removing the link element
		window.URL.revokeObjectURL(url);
		document.body.removeChild(link);
	} catch (error) {
		console.error('Error downloading PDF:', error);
	}
};

export const downloadFileFromURL = async (
	url,
	fileName = `invoice_report_${moment().format(`${DATE_FORMAT.DD_MM_YYYY} h:mm A`)}_${moment().valueOf()}`
) => {
	const response = await fetch(url);
	if (response.ok) {
		const blob = await response.blob();
		const urlObject = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = urlObject;
		link.download = fileName; // You can set the file name here
		document.body.appendChild(link);
		link.click();

		link.remove();
		window.URL.revokeObjectURL(urlObject);
	} else {
		showToast('Sorry!', 'Failed to download file', NOTIFICATION_STATUS_TYPES.ERROR, 'top-right');
	}
};

export const downloadFile = (filePath, fileName = `invoice_${moment().format(`${DATE_FORMAT.DD_MM_YYYY} h:mm A`)}_${moment().valueOf()}.pdf`) => {
	fetch(SERVER_IP + filePath, {
		method: 'GET',
		mode: 'no-cors',
		headers: {
			'Content-Type': 'application/pdf',
			Accept: 'application/pdf',
		},
	})
		.then((response) => response.blob())
		.then((blob) => {
			console.log('🚀 ~ file: index.js:94 ~ blob:', blob);
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		});
};