import React from 'react';
import { Pagination, Row, Col, Input, Button, Switch } from 'antd';
import TableComponent from 'components/table-component';
import PayNowModal from 'pages/outstanding/pay-now';
import { AiOutlineSearch } from 'react-icons/ai';
import { CheckOutlined, ReloadOutlined, CloseOutlined } from '@ant-design/icons';

const OutstandingListPresentational = ({
	filteredData,
	column,
	tableLoading,
	currentPage,
	pageSize,
	initialPageSizeOptions,
	initialPageSize,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	reportType,
	paynowAddModal,
	setPaynowAddModal,
	setPaynowDetails,
	paynowDetails,
	refreshList,
	handleClose,
	setSearchKey,
	isInActiveCheck,
	setIsInActiveCheck,
}) => {
	return (
		<>
			<Row>
				<Col span={reportType ? 24 : 24} style={{}}>
					<TableComponent
						loading={tableLoading}
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						rowKey={(record) => record._id}
						dataSource={filteredData}
						// rowSelection={rowSelection}
						title={() => (
							<Row style={{ justifyContent: 'space-between' }}>
								<Col span={24}>
									<Row gutter={[10, 10]}>
										<Col xl={24}>
											<Row gutter={[10, 10]} align="middle" justify={'space-between'}>
												<Col span={12}>
													<Row gutter={[10, 10]}>
														<Col>
															<Input
																placeholder="Search customer name or mobile"
																suffix={<AiOutlineSearch />}
																style={{ height: '30px' }}
																onChange={({ target: { value } }) => setSearchKey(value)}
															/>
														</Col>
														{/* {selectedRowKeys?.length === 1 ? (
													<Col>
														<Popconfirm
															title={`Are you sure want to delete this Payment?`}
															okText="Delete"
															cancelText="No"
															onConfirm={() => {
																let url = `${SERVER_IP}api/payments/${selectedRowKeys?.[0]}`;
																dispatch(deleteApi('DELETE_PAYMENT', url));
															}}>
															<div style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer' }}>Delete</div>
														</Popconfirm>
													</Col>
												) : null} */}
													</Row>
												</Col>
												<Col span={12}>
													<Row gutter={[10, 10]} align="middle" style={{
														justifyContent: 'flex-end'
													}}>
														<Col>
															<Switch
																className='switch_inactive_user'
																style={{
																	width: '100%',
																}}
																checked={isInActiveCheck}
																onChange={(ev) => setIsInActiveCheck(ev)}
																checkedChildren={
																	<div>
																		<CheckOutlined />
																		Inactive
																	</div>
																}
																unCheckedChildren={
																	<div>
																		<CloseOutlined />
																		Show Inactive
																	</div>
																}
															/>
														</Col>
														<Col>
															<Button
																type="primary"
																icon={<ReloadOutlined />}
																loading={tableLoading}
																onClick={() => refreshList()}
																style={{ marginLeft: 10 }}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col span={12}>
									{!!filteredData?.length && `Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
								</Col>
								<Col span={12}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={initialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData?.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
					/>
				</Col>
			</Row>
			{/* <AddPayment {...{ paymentAddModal, setPaymentAddModal, refreshList, editPayment, handleClose }} /> */}
			<PayNowModal {...{ paynowAddModal, setPaynowAddModal, refreshList, paynowDetails, handleClose }} />
		</>
	);
};

export default OutstandingListPresentational;
