import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { Row, Col } from 'antd';
import moment from 'moment';
// import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { convertToIndianRupees, formQueryStringFromObject, generatePagination, getWhatsAppUrl } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import OutstandingListPresentational from './outstanding-list-presentational';
import { Button, Col, Image, Row } from 'antd';
import { FaMoneyCheck } from 'react-icons/fa';
import MaleSVG from '../../../../assets/images/male.svg';
import FemaleSVG from '../../../../assets/images/female.svg';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const OutstandingListFunctional = React.memo(({ reportType }) => {
	const outstandingRedux = useSelector((state) => state.outstandingRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [paynowAddModal, setPaynowAddModal] = useState(false);
	const [paynowDetails, setPaynowDetails] = useState(null);
	const [isInActiveCheck, setIsInActiveCheck] = useState(false);
	console.log('🚀 ~ OutstandingListFunctional ~ paynowDetails:', paynowDetails);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(outstandingRedux.outstanding);
	const [editPayment, setEditPayment] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [type] = useState('membership');

	const getOutstanding = useCallback(
		(type) => {
			const string = formQueryStringFromObject({
				branchId: globalRedux?.selectedBranch?.id,
				paymentType: type,
				outstanding: true,
				isCustomerActive: !isInActiveCheck
			});
			let url = `${SERVER_IP}api/payments?${string}`;
			dispatch(getApi('GET_OUTSTANDING', url));
		},
		[dispatch, globalRedux?.selectedBranch?.id, isInActiveCheck]
	);

	useEffect(() => {
		getOutstanding(reportType);
	}, [getOutstanding, reportType]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_PAYMENT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_PAYMENT'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getOutstanding(type);
		}
	}, [globalRedux.apiStatus.DELETE_PAYMENT, dispatch, getOutstanding, type]);

	useEffect(() => {
		setTableData(outstandingRedux.outstanding);
	}, [outstandingRedux.outstanding]);

	const handlePaynow = (record) => {
		const newRecord = { ...record };
		newRecord.amount = newRecord?.balance;
		newRecord.balance = 0;
		newRecord.paid = 0;
		newRecord.paidOn = moment();
		newRecord.paymentFor = reportType;
		setPaynowDetails(newRecord);
		setPaynowAddModal(true);
		console.log({ paynowAddModal, paynowDetails, record });
	};

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((outstanding) => {
			return (
				(outstanding?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(outstanding?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(outstanding?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(outstanding?.address || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
				// (outstanding?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	// const handleDrawer = (rowData) => {
	// 	setEditPayment(rowData);
	// 	setPaynowAddModal(true);
	// };

	const handleAddPayment = () => {
		setEditPayment(null);
		setPaynowAddModal(true);
	};

	const columns = {
		product: [
			{
				title: '#',
				dataIndex: 'outstandingNumber',
				key: 'outstandingNumber',
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Pic',
				dataIndex: 'customer',
				key: 'customer',
				width: 50,
				fixed: 'left',
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								{record?.image?.[0]?.url ? (
									<Image
										src={SERVER_IP + record?.image?.[0]?.url}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								) : (
									<img
										src={record?.gender === 'male' ? MaleSVG : FemaleSVG}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
			{
				title: 'Customer Name',
				dataIndex: 'customerName',
				width: '13%',
				fixed: 'left',
				sorter: (a, b) => a?.customerName?.localeCompare(b?.customerName),
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey]}
									autoEscape={true}
									textToHighlight={value || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				width: '15%',
				render: (value, record) => (
					// eslint-disable-next-line
					value !== null 
					?
					<a
						style={{ fontWeight: 'bold' }}
						href={getWhatsAppUrl(
							value,
							`
Hi *${record?.customerName}*,

We hope you're doing well. Our records indicate an outstanding amount of ${
								record?.balance || 0
							} on your account. To avoid any service interruptions, please make your payment at your earliest convenience.

Thank you for your prompt attention to this matter.

Best regards,
*${globalRedux?.selectedBranch?.branchName}* Team`
						)}
						rel="nofollow noopener noreferrer"
						target="_blank">
						<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
					</a>
					:
					<p></p>
				),
			},
			{
				title: 'Product',
				dataIndex: 'productName',
				width: '15%',
				render: (value) => (value || '-') && <span className="membership-button">{value || '-'}</span>,
			},
			{
				title: 'Paid On',
				dataIndex: 'paidOn',
				align: 'left',
				width: '10%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
				),
			},
			{
				title: 'Total Amount',
				dataIndex: 'amount',
				align: 'right',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				,
			},
			{
				title: 'Total Amount Paid',
				dataIndex: 'paid',
				align: 'right',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				,
			},
			{
				title: 'Pending',
				dataIndex: 'balance',
				align: 'right',
				render: (value) => (
					<div
						style={{
							color: 'red',
							fontWeight: 'bold',
						}}>
						<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				
					</div>
				),
			},
			// {
			// 	title: 'Remarks',
			// 	dataIndex: 'remarks',
			// 	align: 'left',
			// 	render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			// },
			{
				title: 'Action',
				dataIndex: 'id',
				align: 'left',
				width: '10%',
				render: (value, row, index) => {
					return (
						<div
							style={{
								display: 'flex',
								gap: 5,
							}}>
							<Button
								title="Pay now"
								type="primary"
								style={{ background: '#3a9f10' }}
								icon={<FaMoneyCheck />}
								onClick={() => handlePaynow(row)}
								disabled={row.isConvertedToCustomer}
							/>
						</div>
					);
				},
			},
		],
		membership: [
			{
				title: '#',
				dataIndex: 'outstandingNumber',
				key: 'outstandingNumber',
				width: '5%',
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Pic',
				dataIndex: 'customer',
				key: 'customer',
				width: 50,
				fixed: 'left',
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								{record?.image?.[0]?.url ? (
									<Image
										src={SERVER_IP + record?.image?.[0]?.url}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								) : (
									<img
										src={record?.gender === 'male' ? MaleSVG : FemaleSVG}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
			{
				title: 'Customer Name',
				dataIndex: 'customerName',
				width: '13%',
				fixed: 'left',
				sorter: (a, b) => a?.customerName?.localeCompare(b?.customerName),
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey]}
									autoEscape={true}
									textToHighlight={value || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				width: '15%',
				render: (value, record) => (
					// eslint-disable-next-line
					value !== null 
					?
					<a
						style={{ fontWeight: 'bold' }}
						href={getWhatsAppUrl(
							value,
							`
Hi *${record?.customerName}*,

We hope you're doing well. Our records indicate an outstanding amount of ${
								record?.balance || 0
							} on your account. To avoid any service interruptions, please make your payment at your earliest convenience.

Thank you for your prompt attention to this matter.

Best regards,
*${globalRedux?.selectedBranch?.branchName}* Team`
						)}
						rel="nofollow noopener noreferrer"
						target="_blank">
						<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
					</a>
					:
					<p></p>
				),
			},
			{
				title: 'Service',
				dataIndex: 'serviceName',
				width: '13%',
				render: (value) => (value || '-') && <span className="membership-button">{value}</span>,
			},
			{
				title: 'Membership',
				dataIndex: 'membershipName',
				align: 'left',
				width: '12%',
				render: (value) => (value || '-') && <span className="membership-button">{value}</span>,
			},
			{
				title: 'Joined on',
				dataIndex: 'joiningDate',
				align: 'left',
				width: '10%',
				render: (value) => (
					<HighlightComponent
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
					/>
				),
			},
			{
				title: 'Total Amount',
				dataIndex: 'amount',
				align: 'right',
				width: '16%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Total Amount Paid',
				dataIndex: 'paid',
				align: 'right',
				width: '16%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Pending',
				dataIndex: 'balance',
				align: 'right',
				width: '7%',
				render: (value) => (
					<div
						style={{
							color: 'red',
							fontWeight: 'bold',
						}}>
						<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
					</div>
				),
			},
			// {
			// 	title: 'Remarks',
			// 	dataIndex: 'remarks',
			// 	align: 'left',
			// 	width: '15%',
			// 	render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			// },
			{
				title: 'Action',
				dataIndex: 'id',
				align: 'left',
				width: '5%',
				render: (value, row, index) => {
					return (
						<div
							style={{
								display: 'flex',
								gap: 5,
							}}>
							<Button
								title="Pay now"
								type="primary"
								style={{ background: '#3a9f10' }}
								icon={<FaMoneyCheck />}
								onClick={() => handlePaynow(row)}
								disabled={row.isConvertedToCustomer}
							/>
						</div>
					);
				},
			},
		],
		personalTraining: [
			{
				title: '#',
				dataIndex: 'outstandingNumber',
				key: 'outstandingNumber',
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Pic',
				dataIndex: 'customer',
				key: 'customer',
				width: 50,
				fixed: 'left',
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								{record?.image?.[0]?.url ? (
									<Image
										src={SERVER_IP + record?.image?.[0]?.url}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								) : (
									<img
										src={record?.gender === 'male' ? MaleSVG : FemaleSVG}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
			{
				title: 'Customer Name',
				dataIndex: 'customerName',
				width: '13%',
				fixed: 'left',
				sorter: (a, b) => a?.customerName?.localeCompare(b?.customerName),
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey]}
									autoEscape={true}
									textToHighlight={value || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				width: '15%',
				render: (value, record) => (
					// eslint-disable-next-line
					value !== null 
					?
					<a
						style={{ fontWeight: 'bold' }}
						href={getWhatsAppUrl(
							value,
							`
Hi *${record?.customerName}*,

We hope you're doing well. Our records indicate an outstanding amount of ${
								record?.balance || 0
							} on your account. To avoid any service interruptions, please make your payment at your earliest convenience.

Thank you for your prompt attention to this matter.

Best regards,
*${globalRedux?.selectedBranch?.branchName}* Team`
						)}
						rel="nofollow noopener noreferrer"
						target="_blank">
						<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
					</a>
					:
					<p></p>
				),
			},
			{
				title: 'Coach Name',
				dataIndex: 'coachName',
				width: '15%',
				render: (value) => (value || '-') && <span className="membership-button">{value || '-'}</span>,
			},
			{
				title: 'Paid On',
				dataIndex: 'paidOn',
				align: 'left',
				width: '10%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
				),
			},
			{
				title: 'Total Amount',
				dataIndex: 'amount',
				align: 'right',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				,
			},
			{
				title: 'Total Amount Paid',
				dataIndex: 'paid',
				align: 'right',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				,
			},
			{
				title: 'Pending',
				dataIndex: 'balance',
				align: 'right',
				render: (value) => (
					<div
						style={{
							color: 'red',
							fontWeight: 'bold',
						}}>
						<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				
					</div>
				),
			},
			{
				title: 'Action',
				dataIndex: 'id',
				align: 'left',
				width: '10%',
				render: (value, row, index) => {
					return (
						<div
							style={{
								display: 'flex',
								gap: 5,
							}}>
							<Button
								title="Pay now"
								type="primary"
								style={{ background: '#3a9f10' }}
								icon={<FaMoneyCheck />}
								onClick={() => handlePaynow(row)}
								disabled={row.isConvertedToCustomer}
							/>
						</div>
					);
				},
			},
		],
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_OUTSTANDING === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setPaynowAddModal(false);
		setEditPayment(null);
	}, [setPaynowAddModal, setEditPayment]);

	// const onTypeChange = (type) => {
	// 	setType(type);
	// };

	return (
		<OutstandingListPresentational
			{...{
				filteredData,
				column: columns[reportType],
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddPayment,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				refreshList: () => getOutstanding(reportType),
				editPayment,
				handleClose,
				reportType,
				paynowDetails,
				setPaynowDetails,
				paynowAddModal,
				setPaynowAddModal,
				isInActiveCheck,
				setIsInActiveCheck,
			}}
		/>
	);
});

export default OutstandingListFunctional;
