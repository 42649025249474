import React, { useState, useMemo } from 'react';
import { Row, Col, Skeleton, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { PieChartOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DATE_FORMAT, MENUS } from 'constants/app-constants';
import { BsFillPersonFill } from 'react-icons/bs';
import { FiArrowUpRight } from 'react-icons/fi';
import { FaCalendarCheck } from 'react-icons/fa';
import RecentPayments from './recent-payments';
import DashboardCard from './dashboard-card';
import { LineChartGradient } from 'components/charts/line-chart';
import { useSelector } from 'react-redux';
// import { LineChartGradient } from 'components/charts/line-chart';

const DashboardPresentational = ({ dashboardData, loginRedux, getDashboardData, payments, loading }) => {
	const navigate = useNavigate();
	const [month, setMonth] = useState(dayjs());
	const productSaleData = useSelector(state => state.dashboardRedux.productSales)
	const membershipSales = useSelector(state => state.dashboardRedux.membershipSales)

	const onMonthChange = (selectedMonth) => {
		setMonth(selectedMonth);
		getDashboardData(new Date(dayjs(selectedMonth)));
	};

	// Memoize cards to prevent re-creation on every render
	const cards = useMemo(() => [
		{
			span: 4,
			icon: <PieChartOutlined />,
			value: dashboardData?.thisMonthData?.totalJoined,
			name: 'Joined this Month',
			description: 'Customers Joined this Month',
		},
		{
			span: 4,
			icon: <FiArrowUpRight />,
			value: dashboardData?.thisMonthData?.totalIncome,
			name: 'Earnings',
			description: 'Total Earnings this Month',
			withPassword: true,
		},
		{
			span: 4,
			icon: <BsFillPersonFill />,
			value: dashboardData?.totalCustomers,
			name: 'Total Customers',
			description: 'Active Customers in Gym',
			onClick: () => navigate(MENUS[1].route),
		},
		{
			span: 4,
			icon: <PieChartOutlined />,
			value: dashboardData?.totalCoaches,
			name: 'Total Coaches',
			description: 'Active Coaches in Gym',
			onClick: () => navigate(MENUS[4].route),
		},
		{
			span: 4,
			icon: <FaCalendarCheck />,
			value: dashboardData?.totalVisitors,
			name: 'Total Visitors',
			description: 'Total Visitors this Month',
			onClick: () => navigate(MENUS[2].route),
		},
		{
			span: 4,
			icon: <FaCalendarCheck />,
			value: dashboardData?.outStanding?.fees,
			name: 'Fees Outstanding',
			description: 'Total Fees Outstanding',
			onClick: () => navigate(MENUS[8].route),
		},
		{
			span: 4,
			icon: <FaCalendarCheck />,
			value: dashboardData?.outStanding?.supplement,
			name: 'Supplement Outstanding',
			description: 'Total Supplement Fees Outstanding',
			onClick: () => navigate(MENUS[8].route),
		},
		{
			span: 4,
			icon: <FiArrowUpRight />,
			value: dashboardData?.thisMonthData?.totalPTEarningsThisMonth,
			name: 'PT Earnings',
			description: 'Total Personal Training Earnings this Month',
			withPassword: true,
		},
		{
			span: 4,
			icon: <FiArrowUpRight />,
			value: dashboardData?.totalPTCount,
			name: 'PT Count',
			description: 'Total Personal Trainings',
		},
		{
			span: 4,
			icon: <FiArrowUpRight />,
			value: dashboardData?.renewedThisMonthCustomers,
			name: 'Renewed this month',
			description: 'Customers Renewed membership this month',
		},
	], [dashboardData, navigate]);

	// Reusable Skeleton Card component
	const SkeletonCard = () => (
		<Col className="title" xl={4} md={6} style={styles.skeletonCard}>
			<Row className="dashboard_card">
				<Col span={19}>
					<Skeleton
						paragraph={{ rows: 1 }}
						active
					/>
				</Col>
			</Row>
		</Col>
	);

	return (
		<Row className="dashboard_container">
			<Col xl={24}>
				<Row gutter={[10, 10]}>
					<Col className="title" span={24} style={styles.title}>
						<Row>
							<Col xl={21} md={20} style={styles.greeting}>
								<h4>
									Hi, {loginRedux?.firstName || ''} {loginRedux?.lastName || ''}! 👋
								</h4>
							</Col>
							<Col xl={3} md={4}>
								<DatePicker
									style={{ width: '100%' }}
									picker="month"
									value={month}
									format={DATE_FORMAT.MMM_YYYY}
									onChange={onMonthChange}
								/>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						{loading ? (
							<Row gutter={[10, 10]}>
								{Array(cards.length).fill(null).map((_, index) => (
									<SkeletonCard key={index} />
								))}
							</Row>
						) : (
							<Row gutter={[10, 10]}>
								{cards.map((card, index) => (
									<DashboardCard key={index} {...card} />
								))}
							</Row>
						)}
					</Col>
					<Col xl={24} style={{ paddingTop: 20 }}>
						<Row gutter={[10, 10]}>
							<Col span={12}>
								<Row gutter={[0, 0]}>
									<Col xl={24} style={styles.salesCard}>
										<Row gutter={[10, 10]}>
											<Col style={styles.cardHeader} span={12}>
												Membership Sales
											</Col>
										</Row>
									</Col>
									<Col className="dashboard_earning_chart" span={24}>
										<LineChartGradient {...membershipSales?.data} />
									</Col>
								</Row>
							</Col> 
							<Col span={12}>
								<Row gutter={[0, 0]}>
									<Col xl={24} style={styles.salesCard}>
										<Row gutter={[10, 10]}>
											<Col style={styles.cardHeader} span={12}>
												Product Sales
											</Col>
										</Row>
									</Col>
									<Col className="dashboard_earning_chart" span={24}>
										<LineChartGradient {...productSaleData?.data} />
									</Col>
								</Row>
							</Col>
							<Col xl={16} md={24}>
								<RecentPayments payments={payments} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default DashboardPresentational;

const styles = {
  title: {
    fontWeight: 'normal',
  },
  greeting: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  skeletonCard: {
    fontWeight: 'normal',
  },
  salesCard: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: '8px 8px 0 0',
  },
  cardHeader: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
};
