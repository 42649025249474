function setDietPlanTemplate(data) {
	return {
		type: 'SET_DIET_PLAN_TEMPLATE',
		payload: data,
	};
}

function setDietPlanTemplateEdit(data) {
	return {
		type: 'SET_DIET_PLAN_TEMPLATE_EDIT',
		payload: data,
	};
}

export { setDietPlanTemplate, setDietPlanTemplateEdit };
