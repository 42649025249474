import React, { useMemo, useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';
import PaymentListFunctional from './payment-list-functional';

const { TabPane } = Tabs;

const PaymentsTab = () => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_PAYMENTS === API_STATUS.PENDING, [globalRedux.apiStatus]);
	const [month, setMonth] = useState(dayjs());

	const onMonthChange = (selectedMonth) => {
		setMonth(selectedMonth);
	};
	return (
		<Row className="payments-tab-container" style={{ height: '80%' }}>
			<Col xl={24} className="container">
				<Row
					style={{
						paddingTop: 20,
					}}>
					<Col xl={21} md={20}>
						Select Month:{' '}
						<DatePicker allowClear={false} style={{ width: 150 }} picker="month" value={month} format={DATE_FORMAT.MMM_YYYY} onChange={onMonthChange} />
					</Col>
					<Col xl={3} md={4}>
						{/* <DatePicker style={{ width: '100%' }} picker="month" value={month} format={DATE_FORMAT.MMM_YYYY} onChange={onMonthChange} /> */}
					</Col>
				</Row>
				<Tabs defaultActiveKey="All" destroyInactiveTabPane>
					<TabPane disabled={tableLoading} tab={'Memberships'} key="Memberships">
						<PaymentListFunctional paymentType="membership"  month={month} />
					</TabPane>
					<TabPane disabled={tableLoading} tab={'Products'} key="Products">
						<PaymentListFunctional paymentType="product" month={month} />
					</TabPane>
					<TabPane disabled={tableLoading} tab={'Personal Training'} key="Personal Training">
						<PaymentListFunctional paymentType="personalTraining" month={month} />
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default PaymentsTab;
