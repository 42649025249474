let initialState = {
	dietPlanTemplate: [],
	editDietPlanTemplate: {}
};

const dietPlanTemplateReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_DIET_PLAN_TEMPLATE':
			return {
				...state,
				dietPlanTemplate: action.payload,
			};
		case 'SET_DIET_PLAN_TEMPLATE_EDIT':
			return {
				...state,
				editDietPlanTemplate: action.payload,
			};
		default:
			return state;
	}
};

export { dietPlanTemplateReducer };
