import React, { useEffect, useState } from 'react';
import { Col, Progress, Row } from 'antd';

const Loader = ({ text = '' }) => {
	const [percent, setPercent] = useState(0);

	useEffect(() => {
		const duration = 3000; // 3 seconds
		const intervalTime = 50; // Update every 50 milliseconds
		const incrementValue = 100 / (duration / intervalTime); // Adjust to complete within 20 seconds

		const interval = setInterval(() => {
			setPercent((prevPercent) => {
				if (prevPercent >= 100) {
					clearInterval(interval);
					return 100;
				}
				return prevPercent + incrementValue;
			});
		}, intervalTime);

		return () => clearInterval(interval);
	}, []);

	return (
		<Row className="common_loader" align="middle">
			<Col span={24}>
				<div className="loader_container">
					<img src={require('../assets/images/logo.png')} style={{ width: 80, height: 80, marginBottom: 40 }} alt="logo" />
					<h3>{text}</h3>
					<Progress percent={percent} type="line" status="active" />
				</div>
			</Col>
		</Row>
	);
};

export default Loader;
