import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, message } from 'antd';
import { DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { downloadAsFileFromResponseData, formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS, DIET_PLAN_CATEGORY_LIST } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import DietPlanListPresentational from './diet-plan-list-presentational';
import axios from 'axios';
import moment from 'moment';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const DietPlanListFunctional = React.memo(() => {
	const [pdfUrl, setPdfUrl] = useState({
		loading: false,
		url: '',
	});
	const dietPlanRedux = useSelector((state) => state.dietPlanRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [dietPlanAddModal, setDietPlanAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(dietPlanRedux.dietPlan);
	const [editDietPlan, setEditDietPlan] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getPeriods = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/period?${string}`;
		dispatch(getApi('GET_PERIODS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getPeriods();
	}, [getPeriods]);

	const getDietPlan = useCallback(() => {
		setEditDietPlan(null);
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/dietPlan?${string}`;
		dispatch(getApi('GET_DIET_PLAN', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getDietPlan();
	}, [getDietPlan]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_DIET_PLAN === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_DIET_PLAN'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getDietPlan();
		}
	}, [globalRedux.apiStatus.DELETE_DIET_PLAN, dispatch, getDietPlan]);

	useEffect(() => {
		setTableData(dietPlanRedux.dietPlan);
	}, [dietPlanRedux.dietPlan]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((dietPlan) => {
			return (dietPlan?.dietPlanName || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditDietPlan(rowData);
		setDietPlanAddModal(true);
	};

	const handleAddDietPlan = () => {
		setEditDietPlan(null);
		setDietPlanAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'dietPlanNumber',
			key: 'dietPlanNumber',
			// fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Customer Name',
			dataIndex: 'customer',
			key: 'customer',
			sorter: (a, b) => a?.customer?.customerName?.localeCompare(b?.customer?.customerName),
			render: (value, record) => (
				<Row align={'middle'}>
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value?.customerName || '-'}
						/>
					</div>
				</Row>
			),
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category',
			sorter: (a, b) => a?.category?.localeCompare(b?.category),
			render: (value, record) => {
				const category = DIET_PLAN_CATEGORY_LIST.filter((item) => item.id === value)[0].categoryName;
				return (
					<Row align={'middle'}>
						<div style={{ fontWeight: 'bold' }}>
							<HighlightComponent
								highlightClassName="highlightClass"
								searchWords={[searchKey]}
								autoEscape={true}
								textToHighlight={category || '-'}
							/>
						</div>
					</Row>
				);
			},
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			// width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'dietPlanName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleView(row)}>
							<EyeOutlined />
						</Col>
						<Col className="download_icon" onClick={() => handleDownload(row)}>
							<DownloadOutlined />
						</Col>
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const handleDownload = async (item) => {
		const fileName = `Diet Plan for ${item?.customer?.customerName} - ${moment().format('DD-MM-YYYY')}.pdf`;
		try {
			setPdfUrl({
				loading: true,
			})
			const response = await axios({
				url: `${SERVER_IP}api/dietPlan/download-report/${item.id}`, // Your Express endpoint
				method: 'GET',
				responseType: 'blob', // Important for handling binary data
			});

			downloadAsFileFromResponseData(response, fileName);
			setPdfUrl({
				loading: false,
			})
		} catch (error) {
			console.error('Error downloading the file:', error);
			setPdfUrl({
				loading: false,
				url: '',
			});
		}
	};

	const handleView = async (item) => {
		try {
			setPdfUrl({
				loading: true,
				url: '',
			})
			const response = await axios({
				url: `${SERVER_IP}api/dietPlan/download-report/${item.id}`, // Your Express endpoint
				method: 'GET',
				responseType: 'blob', // Important for handling binary data
			});

			// Create a Blob URL for the PDF
			const blob = new Blob([response.data], { type: 'application/pdf' });
			const pdfUrl = URL.createObjectURL(blob);

			// Set the PDF URL in state to open the modal with iframe content
			setPdfUrl({
				loading: false,
				url: pdfUrl,
			});
		} catch (error) {
			console.error('Error downloading the file:', error);
			message.error('Error downloading the file');
			setPdfUrl({
				loading: false,
				url: '',
			});
		}
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_DIET_PLAN === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setDietPlanAddModal(false);
		setEditDietPlan(null);
	}, [setDietPlanAddModal, setEditDietPlan]);

	return (
		<DietPlanListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddDietPlan,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				dietPlanAddModal,
				setDietPlanAddModal,
				refreshList: getDietPlan,
				editDietPlan,
				handleClose,
				pdfUrl,
				setPdfUrl,
			}}
		/>
	);
});

export default DietPlanListFunctional;
